// COMPONENTS
import React from 'react'
// CUSTOM TEMPLATES
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/lp/hydra.yml'

// Hydra - A Google Sheets Email List Tool landing page
const HydraLP = () => {
  return <LandingPage content={content} />
}
export default HydraLP
